@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700;900&display=swap');

:root {
    --color-secondary: #7B61FF;
    --color-secondary-light: #D6CCFF;
    --color-secondary-dark: #2700EB;

    --color-success: #9CCC65;
    --color-success-light: #E6F2D9;
    --color-success-dark: #3D581D;

    --color-danger: #FF867C;
    --color-danger-light: #FFD0CC;
    --color-danger-dark: #9E0C00;

    --color-warning: #FFA726;
    --color-warning-light: #FFEACC;
    --color-warning-dark: #754600;

    --color-dark: #000A12;
 	--color-dark-light: #CCCED0;
 	--color-dark-dark: #3B3B3B;

 	--color-light: #F2F2F2;
    --color-light-light: #F2F2F2;
    --color-light-dark: #4A4A4A;

    --color-primary: #03A9F4;
    --color-primary-light: #CEEEFD;
    --color-primary-dark: #015074;

    --color-info: #64D8CB;
    --color-info-light: #D6F5F2;
    --color-info-dark: #175A52;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: #ffffff;
    font-size: 14px;
    background-attachment: fixed;
}

a {
    color: #10A0E3;
}

a:hover,
a:focus {
    color: #25a9b8;
}

hr {
    margin: 2rem 0;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.125)
}

.hr-m1 {
    margin: 0.8rem 0;
}

.btn {
    text-transform: uppercase;
    font-size: 12px;
}

.modal-body .btn.cancel-btn {
    border: solid 1px #cccccc;
}

.modal-api-key .modal-dialog {
    max-width: 560px;
}

.main-cont {
    padding: 3.4rem 7rem 0 5rem;
}

.main-cont-3 {
    padding: 3.4rem 7rem;
}

.main-cont-2 {
    padding: 2rem 3rem;
}

.main-cont-plink {
    padding: 2rem 2.5rem 0rem 2.5rem;
}

.full-w {
    width: 100%;
}

.btn-group-2 .btn {
    width: 50%;
}

.btn {
    font-weight: bold;
}

.btn-lg {
    font-size: 15px;
    padding: 16px;
}

p.small-grey,
label.small-grey {
    color: #7e7e7e;
    font-size: 12px;
    margin: 10px 0 20px 0;
    line-height: 18px;
    cursor: text;
}

label.grey,
.grey {
    color: #b9b7b7;
}

.dark-grey {
    color: #606060;
}

h6.dark-grey {
    letter-spacing: 0;
}

.small-grey {
    color: #b9b7b7;
    font-size: 12px;
}

.small-small {
    font-size: 11px !important;
}

.ml-4 {
    margin-left: 40px;
}

.ml-3 {
    margin-left: 30px;
}

.mr-3 {
    margin-right: 30px;
}

.ml-1 {
    margin-left: 10px !important;
}

.ml-2 {
    margin-left: 20px !important;
}

.mr-2 {
    margin-right: 20px;
}

.mr-1 {
    margin-right: 10px;
}

.inline {
    display: inline-block;
}

.inline-block {
    display: inline-block;
}

p.small-grey-m0 {
    color: #7e7e7e;
    font-size: 12px;
    margin: 0;
}

.cursorPointer {
    cursor: pointer;
}

.m0 {
    margin: 0 !important;
}

p .small-grey-title {
    color: #b9b7b7;
    font-size: 15px;
    margin: 10px 0 20px 0;
}

/* btn colors */

.btn-primary,
.btn-primary:focus,
.btn-outline-primary:focus {
    background-color: #10A0E3;
    border: solid 1px #10A0E3;
    color: #ffffff;
}

.btn-primary:hover,
.btn-outline-primary:hover {
    background-color: #10A0E3;
    border: solid 1px #10A0E3;
}

.btn-outline-light {
    border-color: rgba(0, 0, 0, 0.06);
    color: #606060;
}

.btn-outline-light:hover {
    border-color: rgba(0, 0, 0, 0.06);
    color: #606060;
}

.btn-outline-primary {
    border-color: #10A0E3;
    color: #10A0E3;
}


.btn-success {
    background: #10A0E3;
    background: -webkit-radial-gradient(top left, #10A0E3, #0982C7);
    background: -moz-radial-gradient(top left, #10A0E3, #0982C7);
    background: radial-gradient(to bottom right, #10A0E3, #0982C7);
    border: none;
}

.btn-success:hover {
    background-color: #0982C7;
}

.btn-success:active,
.btn-success:focus {
    box-shadow: 0 0 0 .3rem rgba(0, 0, 0, 0.1) !important;
    background-color: #0982C7;
}

.btn-success.disabled,
.btn-success:disabled {
    background: #10A0E3;
    background: -webkit-radial-gradient(top left, #10A0E3, #0982C7);
    background: -moz-radial-gradient(top left, #10A0E3, #0982C7);
    background: radial-gradient(to bottom right, #10A0E3, #0982C7);
    border: none;
}

.btn-danger {
    background-color: rgb(255, 100, 82);
    border-color: rgb(255, 100, 82);
}

.btn-danger-v2 {
    background-color: #F6C4BF !important;
    color: #000A12;
    border-color: #F6C4BF;
    box-shadow: 0 0 0 0.25rem rgb(246 196 191 / 100%) !important;
}


/* end btn colors */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    letter-spacing: -0.6px;
    color: #333333;
}



.form-floating>.form-control,
.form-floating>.form-select {
    height: 45px;
}

.form-floating>label {
    padding: 10px .75rem;
    font-size: 14px;
}

.form-floating-money>label {
    padding: 12px .75rem 8px 30px;
    font-size: 14px;
    overflow: hidden;
    line-height: 19px;
    text-align: left;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label,
.forceFloatLabel {
    opacity: .65;
    transform: scale(.75) translateY(-.5rem) translateX(.15rem);
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 8px;
    padding-bottom: 0;
}

.popover-body input.form-control,
.popover-body select.form-control {
    font-size: 13px !important;
    height: 33px;
}

.popover-body-2 input.form-control,
.popover-body-2 select.form-control {
    font-size: 13px !important;
    height: 40px;
}

.popover-body-2 select.form-control {
    padding-top: .7rem !important;
}


.popover-body-2 .line-item .input-group-qty {
    right: 10px;
    top: 9px;
}

textarea.form-control {
    font-size: 13px !important;
}

select {
    height: 45px;
    font-size: 14px !important;
}

.form-control:focus {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.05);
}

.form-control.input-error:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 5px rgba(220, 53, 69, 0.1);
}

.input-error-container input{
    border-color: #dc3545;
}

.card {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.05);
}

.card-v2 {
    border-color: rgba(0, 0, 0, 0.1);
    border: solid 13px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

    padding: 1rem 1rem;
}

.card-v3 {
    border-color: rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    border-radius: 5px;

    padding: 1rem 1rem;
}

.card-v4 {
    border-color: rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    border-radius: 5px;
    padding: 1rem 1rem;
}


.card-in {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
}

.header-brand {
    max-width: 132px;
    margin-bottom: 20px;
}

.header-brand-nm {
    max-width: 140px;
    margin: 0;
}

.progress-steps div {
    background-color: #e3e2e2;
    border: solid 3px #ffffff;
    height: 10px;
}

.progress-steps div.active {
    background-color: #10A0E3;
}

.card-info-badge {
    position: absolute;
    top: -5px;
    right: -1px;
    font-weight: normal;
    width: auto !important;
}

.card-brands img {
    margin-left: 6px;
    height: 11px;
}

.card-brands img.visa {
    height: 20px;
}

.card-brands img.amex {
    height: 20px;
}

.card-brands img.discover {
    height: 20px;
}

.card-brands img.mastercard {
    height: 20px;
}

.wallets-accepted img {
    height: 20px;
}

.input-calendar,
.input-cc,
.input-user,
.input-password {
    background-repeat: no-repeat;
    background-position: right 10px top 8px;
    background-size: 25px 25px;
}

.input-calendar {
    background-image: url('../images/calendar.svg');
    background-size: 22px 22px;
    background-position: right 10px top 10px !important;
    font-size: 12px !important;
}

.popover-body .input-calendar {
    background-size: 15px 15px;
    background-position: right 10px top 10px;
}

.input-calendar-time,
.input-cc,
.input-user,
.input-password {
    background-repeat: no-repeat;
    background-position: right 10px top 8px;
    background-size: 25px 25px;
}

.input-calendar-time {
    background-image: url('../images/time.svg');
    background-size: 22px 22px;
    background-position: right 10px top 10px !important;
    font-size: 12px !important;
}

.popover-body .input-calendar-time {
    background-size: 15px 15px;
    background-position: right 10px top 10px;
}

.input-cc {
    background-size: 25px !important;
    padding-left: 45px !important;
    background-position: left 10px center !important;
}

.input-cc-cont label {
    margin-left: 34px;
}

.visa .input-cc {
    background-image: url('../images/visa.svg');
}

.jcb .input-cc {
    background-image: url('../images/jcb.svg');
}

.mastercard .input-cc {
    background-image: url('../images/mastercard.svg');
}

.discover .input-cc {
    background-image: url('../images/discover.svg');
    background-size: 56px;
    background-position: right 10px top 14px;
}

.american-express .input-cc {
    background-image: url('../images/amex.svg');
    background-size: 24px 24px;
    background-position: right 10px top 9px;
}

.diners-club .input-cc {
    background-image: url('../images/dinersclub.svg');
    background-size: 24px 24px;
    background-position: right 10px top 8px;
}

.unknown .input-cc,
.unionpay .input-cc {
    background-image: url('../images/credit-card.svg');
    background-size: 24px 24px;
    background-position: right 10px top 8px;
}

.input-user {
    background-image: url('../images/avatar.svg');
    background-size: 25px 25px;
    background-position: right 10px top 8px;
}

.input-password {
    background-image: url('../images/asterisk.svg');
    background-size: 25px 25px;
    background-position: right 10px top 8px;
}

.sub-header {
    padding: 0;
}

.sub-header-line {
    padding: 0px 0 12px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.review-total {
    font-size: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.review-total .col:last-child {
    text-align: right;
}

.review-total .total-amount .col:last-child {
    font-size: 22px;
    text-align: right;
    font-weight: bold;
}

.review-total .total-amount .col:first-child {
    padding-top: 10px;
}

.top-border-grey {
    padding-top: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}


.form-floating>.form-control,
.form-floating>.form-select,
.input-lg {
    height: 45px;
}

.bottom-border-grey {
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.bottom-grey {
    padding: 20px;
    background-color: rgb(249, 249, 249);
}

.icon-container {
    position: absolute;
    right: 12px;
    top: calc(50% - 14px);
}

.icon-container-10 {
    position: absolute;
    right: 12px;
    top: calc(50% - 10px);
}

.icon-signal {
    max-width: 3.5rem;
    margin: 10px 0 16px 0;
}

.icon-respond {
    margin-right: 8px;
    font-size: 15px;
    transform: rotate(-30deg)
}

.label-info-icon {
    font-size: 14px;
    margin: 0 0 0 3px;
    cursor: pointer;
}

input.big-input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #ced4da;
    font-size: 16px !important;
}

input.big-input:focus {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
}

.icon-edit {
    color: #10A0E3;
    font-size: 20px;
    cursor: pointer;
}

.icon-edit.right {
    position: absolute;
    right: 3rem;
}

ul.sortable,
ul.sorted {
    list-style: none;
    padding-left: 0;
}

.sortable li,
.sorted li {
    background-color: #ffffff;
    padding: 10px 10px 10px 40px;
    background-repeat: no-repeat;
    background-position: left center;
}

.payment-categories .sortable li {
    padding-right: 60px;
    margin-left: -40px;
    margin-right: -60px;
    position: relative;
}

.payment-categories .sorted li {
    padding-right: 60px;
    margin-left: -40px;
    margin-right: -60px;
    position: relative;
}

.sortable-config li {
    padding: 6px 4px 0px 28px;
    margin-left: -28px;
}

.sortable li:hover {
    background-image: url('../images/bx-grid-vertical.svg');
}

.ui-sortable-helper {
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
    /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
    /* Firefox 3.5 - 3.6 */
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}

.popover {
    max-width: 400px;
}

.popover-body {
    min-width: 380px;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
}

.bs-popover-right>.arrow,
.bs-popover-auto[x-placement^="right"]>.arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[x-placement^="right"]>.arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^="right"]>.arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[x-placement^="bottom"]>.arrow {
    top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^="bottom"]>.arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
}

.popover-footer {
    border-top: 1px solid rgba(0, 0, 0, .2);
    margin: 15px -1rem 0;
    padding: 12px 1rem 0 1rem;
}

.popover-body .btn.btn-light {
    font-size: 10px;
}

.popover-footer .btn,
.btn.bordered {
    margin-left: 8px;
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5);
    border: none;
}

.btn.bordered {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5);
    border: none;
}

.btn.bordered-2 {
    font-size: 11px;
    font-weight: normal;
    text-transform: none;
    box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 50%);
    background-color: #fff;
    padding: 25px 0;
}

.btn.bordered-2:hover {
    box-shadow: rgb(146 153 184 / 30%) 0px 0px 30px;
}

.text-normal {
    font-weight: normal;
    text-transform: none;
}

.btn-sm.bordered {
    padding: 2px 12px;
}

.popover-footer .btn:focus {
    background-color: rgba(211, 212, 213, .25)
}

.btn.bordered:focus,
.btn.bordered:hover {
    background-color: rgba(211, 212, 213, .25)
}

div.datatable-actions div.dropdown button:focus,
div.datatable-actions div.dropdown button:hover {
    background-color: rgba(211, 212, 213, .25)
}

.popover-body label.header,
#right-panel label.header {
    font-weight: bold;
    margin-bottom: 6px;
    display: block;
}

.popover-body label.header,
#filter-right-panel label.header {
    font-weight: bold;
    margin-bottom: 6px;
    display: block;
}

.icheck-primary label,
.popover-body label {
    font-size: 12px;
}

.radioIcon {
    font-size: 34px;
    color: #cccccc;
}

.radioIcon.checked {
    font-size: 34px;
    color: #9fcd3a;
}

.popover-body .small-grey {
    font-size: 11px;
    margin-top: -4px;
    margin-bottom: 5px;
}

.card-in {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
}

.card-hover:hover {
    border-color: #10A0E3;
    cursor: pointer;
}

.card-select {
    cursor: pointer;
    border-width: 2px;
}

.delivery-methods .card-select {
    min-height: 142px;
}

.card-select.selected {
    border-color: #10A0E3;
}

.card-selected {
    border: solid 3px #10A0E3;
}

.radio-in-square {
    margin: 0 auto -15px auto;
    font-size: 30px;
    color: #10A0E3;
    background-color: #ffffff;
}

.radio-in-square.disabled {
    color: #cccccc;
}

.card-body.card-header {
    background-color: #ffffff;
    border: none;
}

.grey-icon {
    color: rgba(0, 0, 0, 0.2);
}

.grey-icon-v2 {
    opacity: 0.4;
}

.opacity-panel {
    opacity: 0.3;
    overflow: hidden;
}

.accordion-right-corner {
    right: 12px;
    top: 7px;
    position: absolute;
}

.alert {
    padding: 12px 1rem;
}

.payorFieldsConfig .icheck-primary {
    margin-top: 0 !important;
}

.payorFieldsConfig small {
    font-size: 10px;
}

.payorFieldsConfig .row .p0 {
    padding: 0 15px 0 0 !important
}

.symbol {
    font-size: 20px;
}

.payorFieldsConfig .remove-icon-btn,
.category-icon .remove-icon-btn,
.category-icon .info-icon-btn {
    width: 23px;
    height: 23px;
    cursor: pointer;
    background-color: transparent;
    background-image: url('../images/trash.svg');
    background-size: 17px 17px;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
}

.info-icon-btn {
    background-image: url('../images/bx-info-circle.svg') !important;
}

.payorFieldsConfig .remove-icon-btn:disabled,
.payorFieldsConfig .remove-icon-btn[disabled] {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
    opacity: 0.4;
}

.bs-popover-right,
.bs-popover-bottom {
    box-shadow: 0 0 0 5000px rgba(255, 255, 255, 0.52);

}

.bs-popover-bottom {
    min-width: 680px;
}

.bs-popover-bottom .popover-body {
    min-width: 478px;
}

.cc-form input {
    border: none;
    font-size: 16px;
}

.cc-form input.form-control:focus {
    -webkit-box-shadow: none;
    /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: none;
    /* Firefox 3.5 - 3.6 */
    box-shadow: none;
}

.cc-form {
    border: 1px solid #ced4da;
    border-radius: .25rem;
    margin: 0;
}

.cc-form .cc-field {
    border: none;
    padding: 0;
}

.cc-form .cc-field input {
    padding: 0;
}

.cc-form .cc-field label {
    padding-left: 0;
    padding-right: 0;
}

.cc-form .cc-field:first-child label {
    padding-left: 12px;
}

.search-input {
    background-image: url('../images/bx-search-alt.svg');
    background-repeat: no-repeat;
    background-position: right 7px center;
    padding-right: 40px !important;
}

.loading {
    background-image: url('../images/loadinfo.gif') !important;
}

.payment-categories {
    margin: 0 6rem;
}

.payment-categories .category-icon {
    right: 4px;
    padding-top: 0.5rem;
    text-align: right;
    position: absolute;

}

.payment-categories .remove-icon-btn {
    margin-left: 3px;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #fff;
}

.payorFieldsConfig .form-control:disabled {
    background-color: #e9ecef;
}

.input-money {
    background-image: url('../images/bx-dollar.svg');
    background-repeat: no-repeat;
    background-position: left 7px top 15px;
    background-size: 15px;
    padding-left: 25px !important;
}

.input-money-v2 {
    background-image: url('../images/bx-dollar.svg');
    background-repeat: no-repeat;
    background-position: left 7px center;
    background-size: 15px;
    padding-left: 25px !important;
}

.popover-body .input-money {
    background-position: left 7px top 8px;
}

.input-amount-updautopay {
    width: 6rem;
    border: 0px;
    margin-top: -0.3rem;
    height: 33px !important;
}

.input-amount-upvalue {
    border: 0;
    font-size: 12px;
    text-align: right;
    padding: 0;
    display: inline;
}

.optional-pay {
    position: absolute;
    background-color: #fff;
    z-index: 1;
    right: 66px;
    top: 16px;
}

.input-group-qty {
    width: 64px;
    position: absolute;
    z-index: 1;
    right: 71px;
    top: 22px;
}

.input-group-qty-big {
    top: -5px;
    margin: auto;
}

.input-group-qty-big .btn {
    padding: 0 4px;
    background-color: #fff;
    border: 1px solid #ced4da;
    height: 22px;
}

.input-group-qty-big .btn:hover {
    background-color: #e6e6e6;
}

.input-group-qty-big input.form-control {
    height: 22px;
    padding: 0;
    text-align: center;
}

.input-group-qty-big input.form-control:focus {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.input-group-qty-mr {
    right: 104px !important;
}

.input-group-qty .btn {
    padding: 0 4px;
    background-color: #fff;
    border: 1px solid #ced4da;
    height: 22px;
}

.input-group-qty .btn:hover {
    background-color: #e6e6e6;
}

.input-group-qty input.form-control {
    height: 22px;
    padding: 0;
    text-align: center;
}

.input-group-qty input.form-control:focus {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}


.input-group-qty-inv .btn {
    padding: 0 4px;
    background-color: #fff;
    border: 1px solid #ced4da;
    height: 22px;
}

.input-group-qty-inv .btn:hover {
    background-color: #e6e6e6;
}

.input-group-qty-inv input.form-control {
    height: 22px;
    padding: 0;
    text-align: center;
}

.input-group-qty-inv input.form-control:focus {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.big-amount {
    width: 100%;
    border: none;
    color: #333333;
    text-align: center;
    background: transparent;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: -1px;
    margin: -15px 0 5px 0;
    border-bottom: solid 1px transparent;
}

.big-amount:focus {
    border: none;
    border-radius: 0;
    border-bottom: solid 1px #cccccc;
    outline: none;
}

.icheck-primary>input:first-child:checked+input[type=hidden]+label::before,
.icheck-primary>input:first-child:checked+label::before {
    background-color: #10A0E3;
    border-color: #10A0E3;
    ;
}

.text-transform-normal {
    text-transform: none;
}

.main-top-bar {
    border-bottom: solid 1px #e7e6e6;
    background-color: #ffffff;
    left: 0;
    right: 0;
    position: fixed;
    padding: 0 0 0 235px;
    z-index: 10;
    top: 0;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.02);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.02);
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(228, 226, 226, 1) 51%, rgba(255, 255, 255, 1) 52%);
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.main-top-bar-single {
    background: rgb(255, 255, 255) !important;
}

.main-left-bar {
    border-right: solid 1px #e7e6e6;
    background-color: #ffffff;
    padding: 16px 0px;
    position: fixed;
    width: 245px;
    height: 100%;
    z-index: 11;
    text-align: left;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.03);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.03);
    left: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.main-left-bar-fullscreen{
    border-right: solid 1px #e7e6e6;
    background-color: #ffffff;
    position: fixed;
    width: 100px;
    height: 100%;
    z-index: 11;
    text-align: center;
}

.main-left-bar.open {
    left: -245px;
}

.main-left-bar ul a {
    font-size: 12px;
    padding: 0 20px 0 54px;
    margin: 11px 0 11px 1px;
    color: #5a6a80 !important;
    cursor: pointer;
    -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    transition: all 0.8s ease;
    border-right: 2px solid transparent;
    display: block;
    border-left: 4px solid transparent;
    text-decoration: none;
}

.versionLabel {
    font-size: 11px;
    padding: 0 20px 0 54px;
    margin: 14px 0;
    color: #aaaaaa !important;
}

.main-left-bar ul a:hover,
.main-left-bar ul li.selected a,
.menu-accordion-body a.selected {
    border-left: 4px solid #10A0E3;
    color: #10A0E3 !important;
    font-weight: bold;
}

.main-left-bar ul li {
    list-style: none;
    font-size: 11px;
}

.main-left-bar ul li .header {
    margin-left: 42px;
    font-size: 11px;
    color: #313840;
}

.main-left-bar ul li .icon {
    font-size: 15px;
    display: inline-block;
    margin-top: -4px;
    position: relative;
    left: -10px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.main-left-bar ul li .icon img {
    width: 16px;
}

.main-left-bar ul {
    padding-left: 0;
}

.main-left-bar #main-menu-ul {
    margin-bottom: 20vh;
}

.main-left-bar-fullscreen #main-menu-ul-cc , .main-left-bar-fullscreen .main-menu-bottom ul{
    margin: 0;
    padding: 0;
}

.main-left-bar-fullscreen #main-menu-ul-cc li, .main-left-bar-fullscreen .main-menu-bottom li{
    list-style-type: none;
    color: #5a6a80;
    cursor: pointer;
    margin: 28px 0;
    display: block;
    font-size: 12px;
}

.main-left-bar-fullscreen #main-menu-ul-cc li svg, .main-left-bar-fullscreen .main-menu-bottom li svg, .main-left-bar-fullscreen #main-menu-ul-cc li img{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 30px;
    margin-bottom: 5px;
}

.main-left-bar-fullscreen .main-menu-bottom ul li a{
    color: #5a6a80;
    text-decoration: none;
    font-size: 12px;
}

.ScrollbarsCustom-Track {
    background: none !important;
    width: 4px !important;
}

.ScrollbarsCustom-Track .ScrollbarsCustom-Thumb {
    background-color: rgba(0, 0, 0, 0.1) !important;
}


.main-body {
    padding: 0 44px 0 279px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.main-body-full-screen{
    padding: 0;
}

.main-body.open-menu {
    padding: 0 44px 0 44px;
}

.mt-body {
    padding: 9rem 0 4rem;
}

.mt-body2 {
    padding: 11rem 0 4rem;
}

.mt-body3 {
    padding: 4.5rem 0;
}

.mt-body4 {
    padding-top: 8rem;
}

.mt-body5 {
    padding: 5rem 0;
}

.mt-body6 {
    padding: 48px 0 0 0;
}

.main-menu-bottom {
    bottom: 0;
    position: absolute;
    width: 100%;
    background: #ffffff;
}

.main-top-bar .btn {
    margin: 0 5px;
}

.main-top-bar .btn:last-child {
    margin-right: 0;
}

.main-top-bar .btn:first-child {
    margin-left: 0;
}

#main-branding {
    text-align: center;
    min-height: 80px;
}

#main-branding img {
    width: 100px;
    margin: 12px 0 34px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    opacity: 1;
}

.main-top-bar .top-bar-sub {

    padding: 14px 30px;
}

.main-top-bar .top-bar-sub a:first-child {
    margin-left: 0;
}

.main-top-bar .top-bar-sub a:last-child {
    margin-right: 0;
}

.main-top-bar .top-bar-sub a {
    padding: 0;
    margin: 0 8px;
    color: #333333;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    font-size: 12px;
    text-decoration: none;
}

a.no-underline {
    text-decoration: none;
}

.text-underline {
    text-decoration: underline;
}

.main-top-bar a.icon svg {
    font-size: 21px !important;
}

.main-top-bar .profile-name,
.main-top-bar .org-name {
    display: inline-block;
    margin-right: 20px;
    font-size: 13px;
}

.main-top-bar a.icon {
    margin: 0 6px !important;
    cursor: pointer;
}

.main-top-bar .top-bar-sub a svg {
    font-size: 17px;
    vertical-align: middle;
}

.main-top-bar .top-bar-sub a:hover {
    color: #008bce;
}

.main-top-bar .top-bar-sub input {
    height: 28px;
    font-size: 13px;
}

#div-loading-layer {
    color: #c5cbd3;
}

#div-loading-layer svg {
    animation: rotation 2s infinite linear;
}

#main-loading-layer {
    z-index: 1060;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.52);
    text-align: center;
    color: #10A0E3;
    font-size: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#main-loading-layer svg {
    animation: rotation 2s infinite linear;
}

.table {
    font-size: 12px;
}

.table th {
    border-bottom-color: #dee2e6 !important;
    white-space: nowrap;
}

.table td {
    max-width: 18rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table td:last-child.text-center {
    overflow: inherit;
}

.table .dropdown .btn {
    line-height: 10px;
}

.table .dropdown a,
.actions-btn-cont a {
    font-size: 12px;
}

.table .dropdown a:active,
.dropdown-menu a:active,
.dropdown-item:active {
    background-color: #10A0E3;
    color: #ffffff !important;
}

.dropdown-menu .dropdown-item-text:hover {
    background-color: rgba(234, 236, 239, 0.8);
}

.dropdown-menu .filter-form.dropdown-item-text:hover {
    background-color: rgb(255, 255, 255);
}

.table .dropdown .dropdown-toggle::after,
.actions-btn-cont .dropdown-toggle::after {
    display: none;
}

.table img.ach-icon {
    height: 20px !important;
}

.table img.papercheck-icon {
    height: 20px !important;
}

.table img.card-icon {
    height: 19px !important;
}

.actions-btn-cont .dropdown button:focus {
    box-shadow: none;
}

.badge.badge-border {
    border: 0.9px solid #21212180;
}

.statistic-reports .bg-danger {
    background-color: #FF867C !important;
}

.bg-green {
    background-color: #5d8b38 !important;
}

.payment-icon {
    width: 34px;
}

.card.card-in-border {
    border: solid 8px rgb(0, 0, 0, 0.05)
}

.powered-by-pp-button-preview {
    font-size: 11px;
    color: #b9b7b7;
    margin-top: 10px;
}

.powered-by-pp-button-preview img {
    width: 45px;
    margin-left: 3px;
}

.payment-button-preview .btn {
    padding-left: 40px;
    padding-right: 40px;
}

.color-picker {
    position: absolute !important;
    height: 38px !important;
    padding: 3px !important;
    border: none !important;
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
    background-color: transparent !important;
}

.color-picker span {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
}

.color-picker-input {
    padding-left: 55px;
}

#pp-color-preview {
    text-align: center;
    padding: 2.48rem 3rem;
}

#pp-color-preview .button-cont {
    background-color: #ffffff;
    padding-bottom: 10px;

}

#pp-color-preview .button {
    color: #ffffff;
    font-size: 7px;
    padding: 5px;
    margin: 10px;
}

.toast-success-container {
    background-color: rgb(136, 201, 82) !important;
}

.toast-error-container {
    background-color: rgb(255, 100, 82) !important;
}

.toast-warning-container {
    background-color: rgb(255, 190, 82) !important;
}

input.input-error,
select.input-error,
textarea.input-error {
    border-color: #dc3545;
}

.clear-input-error .input-error{
    border-color: #ced4da;
}

.cc-form.input-error {
    border-color: #dc3545;
}

.icon-modal {
    font-size: 60px;
    margin-bottom: 15px;
    color: #cccccc;
}

#fileImport {
    width: 90px;    
    margin-left: -20px;    
    margin-top: -25px;    
}

#fileuploadurl {
    padding: 5px 0 5px 10px;
    width: 80%;
    cursor: pointer;
    border: 1px solid #ced4da;
    border-radius: 0 4px 4px 0;
    font-size: 12px !important;
}

#fileuploadurl::placeholder {
    color: black;
    opacity: 1;
}
.label-center-align{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -1rem;
    margin-left: -1rem;
}
.label-center-align label{
    margin: 0;
}
.calendar-startdate-sftp{
    font-size: 22px;
    color: #03A9F4;
    cursor: pointer;
}
#modalEditSftp .modal-body h6{
    color: #03A9F4!important;
}
.icon-trash-modal-autopay {
    float: right;
    margin: 0 -30px;
    font-size: 18px;
    color: #f44336;
    cursor: pointer;
}

.icon-trash-invoice {
    color: #f44336;
    cursor: pointer;
    font-size: 15px;
    margin: 0 -8px 4px -8px;
}

.icon-trash-modal {
    color: #000000;
    cursor: pointer;
    font-size: 24px;
}

.modal-body {
    padding: 30px 40px;
}

.modal-autowidth{
    max-width: fit-content;
    width: auto;
    overflow-wrap: break-word;
}

.btn-back {
    color: #333333;
    position: absolute;
    margin: -30px 0 0 -56px;
}

.top-msg-container {
    position: fixed;
    background-color: rgba(47, 47, 51, 1);
    padding: 4px 10px;
    color: #ffffff;
    z-index: 40;
    top: 0;
    font-size: 12px;
    letter-spacing: 0.2px;
}

.bg-decorator {
    height: 50%;
    width: 100%;
    position: fixed;
    opacity: 0.1;
}

.pagination .page-link {
    border: none;
    color: #333333;
}

.link-default {
    font-size: 13px;
    text-decoration: none;
    font-weight: normal;
    margin: 0 10px;
}

.pagination li:first-child .page-link,
.pagination li:last-child .page-link {
    color: #10A0E3;
}

.datatable-actions button, .menu-btn-action-show button {
    margin-left: 20px !important;
    text-transform: none;
    font-weight: normal !important;
}

.datatable-actions .dropdown {
    display: inline-block;
}

.datatable-actions h6 {
    font-size: 13px;
    padding: .25rem .5rem .25rem 0;
}

.datatable-actions .dropdown button, .menu-btn-action-show .dropdown button {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 50%);
    border: none;
    padding: .375rem .75rem;
}

.blue-dropdown-button{
    display: inline-block;
   
}

.blue-dropdown-button .dropdown button{
    background-color: #10A0E3;
    box-shadow: none!important;
    color: #fff;
}

.blue-dropdown-button .dropdown button:hover, .blue-dropdown-button .dropdown.show button{
    background-color: #0c82c7!important;
}

.btn-group button{
    margin-right: 0!important;
}

.btn-group div button{
    margin-left: 0!important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 5px!important;
    padding-right: 5px!important;
}

.datatable-actions .dropdown a,
.datatable-actions .dropdown label,
.menu-btn-action-show a.dropdown-item,
.datatable-actions a.dropdown-item,
table th .dropdown label,
.menu-btn-action-show .dropdown label,
table th .dropdown label {
    font-size: 12px !important;
    cursor: pointer;
    font-weight: normal;
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
}

.datatable-actions .dropdown label input,
table th .dropdown label input {
    margin-right: 4px;
}

.datatable-actions .dropdown .dropdown-toggle::after, .menu-btn-action-show .dropdown .dropdown-toggle::after {
    display: none;
}

.html-code-example {
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, .125);
    padding: .375rem .75rem;
    border-radius: .25rem;
    ;
}

.datatable-actions svg {
    font-size: 14px;
    margin-right: 2px;
}

a.selected {
    color: #10A0E3 !important;
}

#right-panel-layer, #filter-right-panel-layer {
    background-color: rgba(255, 255, 255, 0);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 29;
}

#parentViewSlide {
    position: fixed;
    top: 0;
    right: calc(-100% - 230px);
    width: calc(100% - 230px);
    background-color: #fff;
    height: 100%;
    z-index: 30;
    border-left: solid 1px #e7e6e6;
    padding: 34px 30px 30px;
    -webkit-transition: right 0.3s ease, -webkit-box-shadow 0.3s ease;
    -moz-transition: right 0.3s ease, -moz-box-shadow 0.3s ease;
    transition: right 0.3s ease, box-shadow 0.3s ease;
    overflow-y: scroll;
    overscroll-behavior: contain;
}

#right-panel,
#filter-right-panel,
#vterminal-rigth-panel,
#paybill-rigth-panel {
    position: fixed;
    top: 0;
    right: -534px;
    width: 534px;
    background-color: #fff;
    height: 100%;
    z-index: 30;
    border-left: solid 1px #e7e6e6;
    padding: 34px 30px 30px;
    -webkit-transition: right 0.3s ease, -webkit-box-shadow 0.3s ease;
    -moz-transition: right 0.3s ease, -moz-box-shadow 0.3s ease;
    transition: right 0.3s ease, box-shadow 0.3s ease;
    overflow-y: scroll;
    overscroll-behavior: contain;
}

#right-panel.open,
#filter-right-panel.open,
#parentViewSlide.open,
#vterminal-rigth-panel.open,
#paybill-rigth-panel.open {
    -webkit-box-shadow: -100000px 0px 0px 100000px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: -100000px 0px 0px 100000px rgba(0, 0, 0, 0.4);
    box-shadow: -100000px 0px 0px 100000px rgba(0, 0, 0, 0.4);
}

#right-panel.open,
#vterminal-rigth-panel.open,
#paybill-rigth-panel.open {
    right: 0;
}

#right-panel.open, #filter-right-panel.open, #parentViewSlide.open {
    right: 0;
    margin-right: -15px;
}

#right-panel-close {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    float: right;
    margin-top: -6px;
}

#filter-right-panel-close {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    float: right;
    margin-top: -6px;
}

.table>:not(caption)>*>* {
    padding: .8rem .5rem;
}

#right-panel h5.header,
#vterminal-rigth-panel h5.header,
#paybill-rigth-panel h5.header {
    font-size: 16px;
    letter-spacing: 0;
    padding-bottom: 12px;
    border-bottom: solid 1px #e7e6e6;
}

#filter-right-panel h5.header {
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0.005em;
    padding-bottom: 12px;
    line-height: 24px;
    font-weight: 600;
    border-bottom: solid 1px #e7e6e6;
    color: #000000;
}

#filter-right-panel h5.title {
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #000000;
}

#right-panel .btn-group button,
#filter-right-panel .btn-group button {
    text-transform: capitalize;
}

#right-panel .btn-group button svg,
#filter-right-panel .btn-group button svg {
    font-size: 18px;
}

.head-filter button {
    margin: 0 !important;
}

.head-filter button h6 {
    display: inline-block;
    margin-right: 5px;
    font-size: 13px;
    margin-bottom: 0;
}

.head-filter .btn-check:focus+.btn,
.head-filter .btn:focus,
table th .btn:focus {
    box-shadow: none;
}

table th .btn {
    text-transform: none;
    padding-left: 0;
}

.head-filter button {
    padding-left: 0;
}

table th button {
    padding: 0 !important;
}

table .dropdown-menu,
.menu-btn-action-show .dropdown-menu.show,
.datatable-actions .dropdown-menu {
    margin-top: 5px;
    z-index: 9;
    min-width: 11rem;
    max-height: 60vh;
    overflow-y: auto;
}

table .dropdown-menu label.header {
    font-size: 11px !important;
    margin: -2px 0 2px;
    font-weight: normal;
    display: block;
}

table .dropdown-menu p.small {
    font-size: 12px;
    margin: 0;
    font-weight: normal;
}

.filter-badges .badge {
    margin-right: 10px;
    background-color: #10A0E3;
    padding: 2px 8px;
    display: inline-block;
    cursor: pointer;
}

.filter-badges .badge svg {
    font-size: 16px;
}

span.badge svg {
    font-size: 12px;
    margin-bottom: 2px;
}

.dropdown-menu .form-control {
    font-size: 12px;
}

.dropdown-menu .form-control.search-enter {
    background-image: url('../images/bx-subdirectory-left.svg');
    background-repeat: no-repeat;
    background-position: center right 3px;
    background-size: 18px;
    padding-right: 26px;
}

table th .search .dropdown-menu {
    min-width: 18rem;
}

.filter-form-datepicker .react-datepicker,
.filter-form-datepicker .react-datepicker__month-container {
    width: 100%;
}

.filter-form-datepicker .react-datepicker {
    border: none;
    margin: -.5rem 0;
    font-weight: normal;
}

.filter-form-datepicker.dropdown-item-text {
    padding: 0;
}

.filter-form-datepicker .react-datepicker__header {
    padding-top: 10px;
    border-radius: 0 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #E1F5FE !important;
    color: #333333 !important;
}

.green-text {
    color: #92d050;
}

.light-text {
    color: rgba(0, 0, 0, 0.2);
}

.red-text {
    color: rgb(255, 100, 82);
}
.red-text-negative-number{
    color: #9E0C00;
}
.blue-text {
    color: #10A0E3;
}

.icon-side-text {
    font-size: 24px;
    margin: -3px 6px 0 6px;
}

h6.sub-header-line.dark-grey {
    font-size: 15px;
}

.dashboard-items label {
    font-size: 12px;
    color: #606060;
}

.dashboard-color-circle {
    float: left;
    margin-right: 15px;
    text-align: center;
    font-size: 34px;
    width: 60px;
    height: 60px;
    padding-top: 2px;
    margin-top: -5px;
    border-radius: 50px;
}

.color-circles.small label {
    font-size: 10px;
}

.small .dashboard-color-circle {
    margin-right: 6px;
    font-size: 20px !important;
    width: 36px;
    height: 36px;
    margin-top: 0px;
}

.small .dashboard-color-circle svg {
    padding-top: 0 !important;
}

.color-circles>div {
    padding-right: 0px;
}

.dashboard-color-circle.orange {
    background-color: #FFC107;
    color: #000A12;
}

.dashboard-color-circle.green {
    background-color: #88C952;
    color: #000A12;
    font-size: 27px;
    padding-top: 8px;
}

.dashboard-color-circle.red {
    background-color: rgb(254, 192, 192);
    color: rgb(254, 44, 46);
}

.dashboard-color-circle.blue {
    background-color: rgb(170, 190, 225);
    color: rgb(16, 105, 182);
}

.dashboard-color-circle.lightBlue {
    background-color: #64B5F6;
    color: #000A12;
}

.dashboard-color-circle.teal {
    background-color: #64D8CB;
    color: #000A12;
}

.dashboard-color-circle.purple {
    background-color: rgb(196, 143, 250);
    color: rgb(100, 43, 146);
}

.dashboard-color-circle.greenoutline {
    background-color: #ffffff;
    color: rgb(43, 184, 83);
    border: solid 2px rgb(43, 184, 83);
    padding-top: 0px;
}

.dashboard-color-circle.redoutline {
    background-color: #ffffff;
    color: rgb(254, 44, 46);
    border: solid 2px rgb(254, 44, 46);
    padding-top: 0px;
}

.dashboard-color-circle.yellowoutline {
    background-color: #ffffff;
    color: #ffc107;
    border: solid 2px #ffc107;
    padding-top: 0px;
}

.card-no-border {
    border: none;
    box-shadow: none;
}

.card-no-border .card-body {
    padding: 0;
}

.card-no-border .card-header {
    padding: 0;
    background: 0;
    border: none;
    cursor: pointer;
}

.card-no-border svg {
    display: inline-block;
    margin-right: 0px;
    margin-left: -18px;
}

.info-message-card {
    margin: 15px 0 30px 0 !important;
    text-align: center;
}

.ul-nomargin {
    padding-left: 0;
}

.ul-nomargin li {
    list-style: none;
}

.vterminal-tabs {
    margin: -3.4rem -7rem 0rem -7rem !important;
}

.vterminal-tabs a {
    border-radius: 0 !important;
    padding: 20px;
    border: none !important;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1) !important;
}


.vterminal-tabs .nav-link.active {
    border-bottom: solid 1px #ffffff !important;
    color: #209ad4;
}

.vterminal-tabs .nav-item:first-child a {
    border-right: solid 1px rgba(0, 0, 0, 0.1) !important;
}

.vterminal-tabs .nav-link {
    background-color: #f2f2f2;
    color: #757070;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1) !important;
}

.search-dropdown-menu {
    position: absolute;
    width: 100%;
    margin-top: -4px;
    padding: .5rem 0;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    background-color: #fff;
    z-index: 10;
    max-height: 400px;
    overflow-y: auto;
}

.search-dropdown-menu .dropdown-item {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.separatorBetween span:not(:first-child)::before{
    content: " | ";
}

.line-item .input-group-qty {
    right: 15px;
    top: 12px;
}

.dropdown-item .dropdown-item {
    padding-left: 0;
}

.popover-body .form-floating label {
    padding-top: 8px !important;
}

.btn-bordered-success {
    background-color: #10A0E3 !important;
    font-weight: normal;
    color: #ffffff;
    text-transform: none;
    box-shadow: 0 0 0 .25rem #0ca1e24f;
    -webkit-box-shadow: 0 0 0 .25rem #0ca1e24f;
    -moz-box-shadow: 0 0 0 .25rem #0ca1e24f;
}

.btn-bordered-success:hover,
.btn-bordered-success:active,
.btn-bordered-success:visited {
    color: #ffffff !important;
}

.card-payment-method {
    width: 410px;
    height: 255px;
    margin: 0 25px 25px 0 !important;
    padding: 2rem;
    display: inline-block;
    vertical-align: top;
}

.card-payment-method .datatable-actions .dropdown button {
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding-top: 0;
    padding-right: 0;
}

.card-payment-method .datatable-actions .dropdown button svg {
    font-size: 20px;
    color: #10A0E3;
}

.card-payment-method-add {
    border: dashed 1px rgba(0, 0, 0, 0.3) !important;
    padding-top: 60px;
    border-radius: 8px;
}

.btn-dropdown-top-menu {
    display: inline-block;
}

.btn-dropdown-top-menu .dropdown-menu {
    margin-top: 5px;
}

.btn-dropdown-top-menu button {
    display: inline-block;
    margin: 0 6px !important;
    padding: 0;
}

.btn-dropdown-top-menu button:focus {
    box-shadow: none;
}

.btn-dropdown-top-menu button svg {
    font-size: 20px;
}

.btn-dropdown-top-menu a {
    font-size: 12px !important;
    cursor: pointer;
    font-weight: normal;
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
    padding: .25rem 1rem !important;
    margin-left: 0 !important;
}

.btn-dropdown-top-menu a:hover {
    color: #333333 !important;
}

.table-icon svg {
    font-size: 25px;
    margin: -5px 0;
}

.trending-up {
    color: rgb(36, 193, 143);
    font-weight: bold;
    margin-right: 5px;
    font-size: 12px;
}

.trending-down {
    color: rgb(255, 77, 79);
    font-weight: bold;
    margin-right: 5px;
    font-size: 12px;
}

.trending-equal {
	color: var(--color-light-dark);
	font-weight: bold;
	margin-right: 5px;
	font-size: 12px;
}

.box-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chart-center {
    position: absolute;
    z-index: 10;
}

.chart-tab .nav-tabs {
    border: none;
}

.chart-tab .nav-tabs .nav-item a {
    border: none;
    color: #333333;
    padding: 12px 20px;
    font-size: 14px;
}

.chart-tab .nav-tabs .nav-item a:hover {
    box-shadow: rgb(146 153 184 / 15%) 0px 15px 30px;
    background-color: #fff;
}

.chart-tab .nav-tabs .nav-item a.active {
    background-color: rgb(248, 249, 251);
}

.chart-tab .trending-up {
    font-weight: normal;
    font-size: 11px;
}

.fl-capitalize {
    text-transform: capitalize;
}

.profile-image {
    border: dashed 1px #333;
    padding: 15px 24px;
    display: inline-block;
    font-size: 30px;
    cursor: pointer;
}

.root-main {
    position: fixed;
    height: 100%;
    width: 100%;
    background: repeating-linear-gradient(90deg, #0c55ea, #3e6bc8, #68b1d4, #a2e0e6, #68b1d4, #3e6bc8, #0c55ea);
    background-repeat: repeat-x;
    background-size: 400% 100%;
    animation: AnimationName 13s linear infinite;
}

.root-page {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #f4f5f7;
}

@keyframes AnimationName {
    0% {
        background-position: 100% 50%
    }

    100% {
        background-position: -33% 50%
    }
}

.card-login {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 62px 0px rgb(0 0 0 / 27%);
    box-shadow: 0px 0px 62px 0px rgb(0 0 0 / 27%);
    padding: 60px 80px;
    border-radius: 5px;
    /*min-width: 480px;*/
}


.right-icon-on-input {
    position: absolute;
    top: 12px;
    right: 14px;
    font-size: 20px;
    cursor: pointer;
}

.modal-body .right-icon-on-input {
    top: 7px;
}

.css-1okebmr-indicatorSeparator {
    display: none;
}

.gautocomplete div[class$="-control"] {
    border-color: none !important;
    box-shadow: none !important;
}

div.input-error .gautocomplete div[class$="-control"] {
    border-color: #dc3545 !important;
}


.gautocomplete div[class$="-control"]:hover {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.05);
}

.hamburgerButton {
    font-size: 22px;
    position: absolute;
    right: -65px;
    top: 14px;
    cursor: pointer;
    color: #555555;
    -webkit-box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 1);
    background-color: #ffffff;
}

.hide {
    display: none;
}

.sm-screen-topbar-menu a {
    margin: 0 10px !important;
}

.sm-screen-topbar-menu a svg {
    font-size: 20px !important;
}

#vterminal-container {
    width: 50em;
}

.report-container {
    width: 100%;
    overflow: auto;
    min-height: 73vh;
}

.report-tools{
	position: absolute;
	min-width: 658px;
	right: 30px;
}

.offset-lg-1 .report-container {
    min-height: 0px;
}

.main-menu .menu-accordion-body {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

.main-menu .menu-accordion-body a:first-child {
    margin-top: 0;
}

.main-menu .menu-accordion-body a {
    padding-left: 73px;
    padding-right: 20px;
    font-size: 11px;
}

.menu-accordion-body-popover a {
    font-size: 12px;
    display: block;
    margin: 6px 0;
    color: #5a6a80 !important;
    text-decoration: none;
}

.menu-accordion-body-popover a:hover {
    color: #000000 !important;
}

.main-menu .menu-accordion-body a.sub-menu {
    padding-left: 85px;
}

.loginBrand, .pageNotFoundloginBrand img{
    max-width: 110px;
    margin-bottom: 25px;
}

#main-branding-icon {
    text-align: right;
    width: 100%;
}

#main-branding-icon-fullscreen img{
    width: 40%;
    margin: 30px 0;
}

#main-branding-icon img{
    width: 32px;
    margin: 12px 0 34px;
    top: 10px;
    right: 130px;
    position: absolute;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    opacity: 0;
}

.payment-page-name input:disabled {
    background-color: #eeeeee !important;
}

.react-switch-slider-menu {
    float: right!important;
}

.password-strength-meter-progress {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
}

.password-strength-meter-progress::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
}

.password-strength-meter-label {
    font-size: 14px;
}

.password-strength-meter-progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
    background-color: #F25F5C;
}

.strength-Fair::-webkit-progress-value {
    background-color: #FFE066;
}

.strength-Good::-webkit-progress-value {
    background-color: #247BA0;
}

.strength-Strong::-webkit-progress-value {
    background-color: #70C1B3;
}

.page-item.active .page-link {
    background-color: #10A0E3;
    border-color: #10A0E3;
    border-radius: 2px;
}

.coming-soon {
    width: 70em;
}

.coming-soon img {
    max-height: 300px;
    max-width: 100%;
    margin-bottom: 30px;
}

.coming-soon .img-container {
    height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.tooltip {
    font-size: 13px;
}

.tooltip .tooltip-inner {
    padding: 10px;
    background-color: #ffffff;
    font-size: .875em;
    color: #000;
    box-shadow: 0 0 0 4px rgb(255 255 255 / 52%);
    border: 1px solid rgba(0, 0, 0, .2);
    text-align: left;
}

.dataTooltip .tooltip-inner {
    min-width: 360px;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[data-popper-placement^=top],
.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=top] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0
}

.bs-tooltip-auto[data-popper-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
    top: -1px;
    border-width: .4rem .4rem 0;
    border-top-color: #ffffff
}

.bs-tooltip-auto[data-popper-placement^=right],
.bs-tooltip-end {
    padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=right] .arrow,
.bs-tooltip-end .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=right] .arrow::before,
.bs-tooltip-end .arrow::before {
    right: -1px;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #ffffff
}

.bs-tooltip-auto[data-popper-placement^=bottom],
.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    bottom: -1px;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #ffffff
}

.bs-tooltip-auto[data-popper-placement^=left],
.bs-tooltip-start {
    padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=left] .arrow,
.bs-tooltip-start .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=left] .arrow::before,
.bs-tooltip-start .arrow::before {
    left: -1px;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #ffffff
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background: #03A9F4 !important;
    color: #ffffff !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker .react-datepicker__header button {
    border: solid 1px #cccccc;
}

.react-datepicker .react-datepicker__header select {
    border: solid 1px #cccccc;
    height: 25px;
}

.react-datepicker-popper .linkCalendar {
    text-align: center;
    padding: 20px 0;
    cursor: pointer;
    text-decoration: underline;
}

.react-datepicker-popper .react-datepicker {
    font-family: 'Poppins', sans-serif !important;
}

.records-not-found {
    text-align: center;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.places-autocomplete-dropdown-container {
    background-color: #ffffff;
    border: solid 1px #ced4da;
    border-radius: .25rem;
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: -15px;
}

.places-autocomplete-dropdown-container .suggestion-item,
.places-autocomplete-dropdown-container .suggestion-item-active {
    font-size: 12px;
    padding: 5px 10px;
    cursor: pointer;
}

.places-autocomplete-dropdown-container .suggestion-item-active {
    background-color: rgba(0, 0, 0, 0.05);
}

.mini-inner {
    position: absolute;
    top: 7px;
    right: -11.4rem;
}


.optionalLabelInfo {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 14px;
    font-size: 10px;
}

.filterPanelV2 .dropdown-menu {
    min-width: 200px;
}

.float-right {
    float: right;
}

.cursor-pointer {
    cursor: pointer;
}


.disabled-transparent {
    opacity: 0.6;
}

.refreshIcon {
    font-size: 23px;
    margin-left: 6px;
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
    color: #606060;
}

table td.avatarTd {
    min-width: 250px;
}

.avatar-container {
    width: 35px;
    height: 35px;
    border-radius: 100px;
    display: inline-block;
    margin-right: 12px;
    color: #ffffff;
    font-size: 14px;
    padding: 7px 0;
    text-align: center;
    font-weight: bold;
}

.p-actions {
    border-radius: 4px;
    border: solid 1px transparent;
    width: 28px;
    display: block;
    background-color: #ffffff;
    padding: 4px 6px;
    text-align: right;
    overflow: hidden;
}

.p-actions.open {
    width: 95px;
    border: 1px solid rgba(0, 0, 0, .2);
    padding: 4px 6px;
}

.timeLineRecord {
    display: inline-block;
    top: -8px;
}

.timeLineRecordText {
    width: 270px;
    font-size: 11px;
    display: inline-block;
    padding-left: 15px;
    margin-bottom: 14px;
}

.timeLineRecordText b {
    font-size: 13px;
    padding-top: 10px;
}

.timeLineRecordContainer .timeLineRecordItem .timeLineRecord {
    position: relative;
    border-radius: 100%;
    width: 16px;
    line-height: 16px;
    text-align: center;
    margin-top: 15px;
    background-color: #88c952;
    z-index: 2;
}

.timeLineRecordContainer .timeLineRecordItem .timeLineRecord:before {
    position: absolute;
    border: 1px solid #88c952;
    width: 0;
    height: 50px;
    display: block;
    content: '';
    left: 50%;
    z-index: 1;
    top: -15px;
    margin-left: -1px;
}

.timeLineRecordContainer .timeLineRecordItem:last-of-type .timeLineRecord:before {
    height: 25px;
}

.timeLineRecordContainer .timeLineRecordItem:first-of-type .timeLineRecord:before {
    top: 5px;
}

.timeLineRecordContainer .timeLineRecordItem:only-child .timeLineRecord:before {
    border: none !important;
}


.addInvoice {
    padding: 20px 15px;
    border-radius: 10px;
    border: solid 2px #cccccc;
    width: 200px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: table;
    margin-left: 40%;
    text-align: center;
    color: #7e7e7e;
    cursor: pointer;
    background-color: #ffffff;
    font-weight: bold;
    font-size: 11px;
}

.addInvoice h5 {
    color: #636363;
    text-transform: uppercase;
}

.addInvoice div {
    background-image: url('../images/loadInvoicebg.png');
    height: 100px;
    width: 100%;
    background-size: 100% 100%;
    background-position: center;
    margin: 20px 0;

}

.statistic-reports {
    text-align: center;
    font-size: 11px;
}

.statistic-reports span {
    color: #000A12 !important;
}

.statistic-reports div.item {
	transition: transform 0.2s ease;
    border-right: solid 1px #e7e6e6;
    padding: 0 18px;
    min-width: 180px;
	display: inline-block;
	cursor: pointer;
	margin: 20px 0;
}

.statistic-reports div.item .column:first-child{
    padding-right: 0!important;
}

.statistic-reports div.item .column:last-child{
    padding-left: 0!important;
}

.statistic-reports div.item:hover {
	transform: scale(1.2);
    border-right: solid 1px #ffffff;
}

.statistic-reports div.item:last-child {
    border-right: none;
}

.statistic-reports p {
    font-size: 11px;
    margin-bottom: 5px;
}

.statistic-reports span {
    background-color: #88c952;
    color: #ffffff;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 20px;
    float: right;
    min-width: 24px;
}

.approvalActions .approve,
.approvalActions .disapprove,
.approvalActions .disabled {
    font-size: 22px;
    cursor: pointer;
}

.approvalActions .approve {
    color: #88c952;
}

.approvalActions .disapprove {
    margin-left: 5px;
    color: #dc3545;
}

.approvalActions .disabled {
    margin-left: 5px;
    color: #cccccc;
}

.panelbutton-secondary {
    box-shadow: none !important;
    width: 100%;
    margin-left: 0 !important;
    border: solid 3px rgb(211 212 213 / 50%) !important;
    padding: 12px;
    color: #666666 !important;
}

.accordionHeader {
    background: none;
    border: none;
    text-align: left;
    padding: 0;
    cursor: pointer;
}

.accordionHeader h6 {
    font-size: 14px;
}

.accordionHeader p {
    font-weight: normal;
    font-size: 12px;
    margin: 2px 0 20px 0 !important;
    line-height: 18px;
    cursor: pointer;
}

.accordionHeader svg {
    position: absolute;
}

.accordionHeader svg.accordionArrow {
    margin: 4px 0 0 -30px;
    font-size: 25px;
}

.form-floating .inner-addon {
    margin: -28px 8px;
    position: absolute;
}

.left-addon .inner-addon {
    left: 0;
}

.right-addon .inner-addon {
    right: 0;
}

.widthAuto{
    width: auto;
}

.badge {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.004em;
    padding: 0px 4px;
    border-radius: 3px;
}

.section-status span.badge {
    display: inline-flex;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.048px;    
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
}

.section-status span.badge.text-secondary {
    border: 1px solid var(--color-secondary);
}
.section-status span.badge.text-success {
    border: 1px solid var(--color-success);
}
.section-status span.badge.text-danger {
    border: 1px solid var(--color-danger);
}
.section-status span.badge.text-warning {
    border: 1px solid var(--color-warning);
}
.section-status span.badge.text-light {
    border: 1px solid var(--color-light-dark);
}
.section-status span.badge.text-primary {
    border: 1px solid var(--color-primary);
}
.section-status span.badge.text-info {
    border: 1px solid var(--color-info);
}

/* BADGE-COLOR */
.bg-info {
    background-color: var(--color-info-light) !important;
    color: var(--color-info-dark) !important;
}
.bg-primary {
    background-color: var(--color-primary-light) !important;
    color: var(--color-primary-dark) !important;
}
.bg-secondary {
    background-color: var(--color-secondary-light) !important;
    color: var(--color-secondary-dark) !important;
}
.bg-success {
    background-color: var(--color-success-light) !important;
    color: var(--color-success-dark) !important;
}
.bg-warning {
    background-color: var(--color-warning-light) !important;
    color: var(--color-warning-dark) !important;
}
.bg-danger {
    background-color: var(--color-danger-light) !important;
    color: var(--color-danger-dark) !important;
}
.bg-light{
    background-color: var(--color-light-light) !important;
    color: var(--color-light-dark) !important;
}

/* TEXT-COLOR */
.text-primary{
    color: var(--color-primary) !important;
}
.text-secondary{
    color: var(--color-secondary) !important;
}
.text-success{
    color: var(--color-success) !important;
}
.text-danger{
    color: var(--color-danger) !important;
}
.text-warning{
    color: var(--color-warning) !important;
}
.text-info{
    color: var(--color-info) !important;
}
.text-light{
    color: var(--color-light-dark) !important;
}

.bg-dark {
    background-color: #94a3ad !important;
}
.text-dark{
    color: #03A9F4 !important;
}
.bg-secondary-2 {
    background-color: #F2F2F2 !important;
    color: #4A4A4A !important;
}

.responsive-1330-container {
    overflow-x: auto;
}

.cursor-pointer {
    cursor: pointer;
}

.color-picker {
    padding: 3px !important;
}

div>ul.pagination-rows-page>li a,
div>nav>ul.pagination-rows-page>li a {
    color: #03A9F4 !important;
}

div>ul.pagination-rows-page>li a.disabled {
    color: #212529 !important;
}

div>ul.pagination-rows-page>li.active a,
div>nav>ul.pagination-rows-page>li.active a {
    color: #fff !important;
}

div>nav>ul.pagination-rows-page>li.break-me {
    margin-top: 2px !important;
}

.mt-body4 .report-paginator, .slide-view-container .report-paginator {
    bottom: 0;
    position: sticky;
    background-color: white;
    padding-top: 10px;
    z-index: 1;
}

.slide-view-container div.report-paginator {	    	
    background-color: white;
    padding-top: 10px;
    z-index: 1;
    position: absolute;
    width: calc(100% - 70px);
}

.mt-body4 .report-paginator ul.pagination, .slide-view-container div.report-paginator ul.pagination {
    margin-bottom: 0;
}

/* FILTER PANEL */

/* Divider */
.divider {
    /* minor cosmetics */
    display: table;
    font-size: 24px;
    text-align: center;
    width: 100%;
    /* divider width */
    /*margin: 40px auto;*/
    /* spacing above/below */
}

.divider span {
    display: table-cell;
    position: relative;
}

.divider p {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    border-radius: 15px;
    padding: 5px 5px 5px 10px;
    border: 1px solid #21212133;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0025em;
    text-transform: uppercase;
    cursor: pointer;
}

.divider span:first-child,
.divider span:last-child {
    width: 50%;
    top: 13px;
    /* adjust vertical align */
    -moz-background-size: 100% 3px;
    /* line width */
    background-size: 100% 3px;
    /* line width */
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
}

.divider span:first-child {
    /* color changes in here */
    background-image: -webkit-gradient(0, from(transparent), to(#21212133));
    background-image: -webkit-linear-gradient(0, transparent, #21212133);
    background-image: -moz-linear-gradient(0, transparent, #21212133);
    background-image: -o-linear-gradient(0, transparent, #21212133);
    background-image: linear-gradient(0, transparent, #21212133);
}

.divider span:nth-child(2) {
    color: rgba(33, 33, 33, 0.5);
    width: auto;
    white-space: nowrap;
}

.divider span:nth-child(2):hover {
    background-color: rgba(211, 212, 213, .25);
    border-radius: 15px;
}

.divider span:last-child {
    /* color changes in here */
    background-image: -webkit-gradient(0, from(#21212133), to(transparent));
    background-image: -webkit-linear-gradient(0, #21212133, transparent);
    background-image: -moz-linear-gradient(0, #21212133, transparent);
    background-image: -o-linear-gradient(0, #21212133, transparent);
    background-image: linear-gradient(0, #21212133, transparent);

}

#filter-right-panel hr {
    margin: auto;
    display: block;
}

#filter-right-panel.open div.btnFilter {
    background-color: #FFFFFF;
    padding: 10px 18px 0 0;
    height: 70px;
    bottom: 0;
    right: 0;
    position: fixed;
    width: 496px;
    -webkit-transition: right 0.3s ease, -webkit-box-shadow 0.3s ease;
    -moz-transition: right 0.3s ease, -moz-box-shadow 0.3s ease;
    transition: right 0.3s ease, box-shadow 0.3s ease;
}

#filter-right-panel div.btnFilter {
    right: -505px;
}

#filter-right-panel div p {
    margin-bottom: 0;
}

.btn-filter-reset {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    width: 20%;
    height: 38px;
    background: #F8F8F7;
    border: 3px solid #EAEAE9;
    border-radius: 8px;
    float: left;
    margin-right: 8px;
}

.btn-filter-see {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    width: 77%;
    height: 38px;
    background: #03A9F4;
    border: 3px solid #EAEAE9;
    border-radius: 8px;
    float: left;
    color: #FFFFFF;
}

.modal-import-width {
    min-width: 597px !important;
}

#popover-time div.popover-body form div span {
    margin: 5px;
}

#filter-right-panel div.section-status span {
    cursor: pointer;
    margin: 10px 10px 0 0;
    transform: scale(1);
    transition: transform 0.3s ease-out;
}

#filter-right-panel div.section-status span:hover {
    transform: scale(1.1);
}

#filter-right-panel div.section-payment-information div.col-2 {
    width: 80px;
    padding-right: 1px;
}

#filter-right-panel div.section-payment-information span {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    gap: 10px;
    background: #FFFFFF;
    border: 0.5px solid rgba(33, 33, 33, 0.2);
    border-radius: 8px;
    height: 50px;
    cursor: pointer;
}

#filter-right-panel div.row.section-payment-information span.disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

#filter-right-panel input.disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

#filter-right-panel div.disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

#filter-right-panel div.section-payment-information p {
    font-size: 9px;
    line-height: 14px;
    display: block;
    letter-spacing: 0.0015em;
    text-transform: uppercase;
    text-align: center;
    margin-top: 4px;
}

#filter-right-panel div.section-payment-information span.section-payment-information-active {
    border: 1px solid #03A9F4;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(44, 43, 42, 0.1);
}

#filter-right-panel input[type="radio"],
#filter-right-panel input[type="checkbox"] {
    margin-top: 8px;
    vertical-align: middle;
}

#popover-time div.popover-body form .form-group {
    width: 90px;
}

#popover-time div.popover-body form .popover-time-select {
    overflow: hidden;
    width: 45px;
    float: left;
    border: 1px solid #ccc !important;
    padding-top: 5px;
}

#popover-time div.popover-body form div select {
    height: 200px;
    width: calc(100% + 18px);
    border: none;
    overflow-y: auto;
    padding-top: 0;
}

#filter-right-panel .input-number input::-webkit-outer-spin-button,
#filter-right-panel .input-number input::-webkit-inner-spin-button {
    display: none;
}

#popover-payment-terms div.popover-body {
    overflow: hidden;
}

#popover-payment-terms div.popover-body .payment-terms {
    max-height: 200px;
    overflow: auto;
    margin-right: -34px;
    padding-right: 14px;
}

#popover-time div.popover-body form select.select-time option {
    cursor: pointer;
}

#popover-time div.popover-body form select.select-time option:hover {
    font-weight: bold;
}

#popover-time div.popover-body form select.select-time option:checked {
    background: #03A9F4 !important;
    color: #ffffff !important;
}

#filter-right-panel .form-floating label {
    padding-top: 10px !important;
}

#filter-right-panel div.section-frequency {
    font-size: 20px;
    padding-left: 10px;
}

#filter-right-panel div.p-to-bill-details {
    color: rgb(33, 33, 33, 0.5);
    margin: 10px -20px 0 -20px;
    width: 40px !important;
}

/* END FILTER PANEL */

.a-tabs-style.small-screen a {
    padding: 10px;
}

.a-tabs-style a {
    display: inline-block;
    padding: 10px 20px;
    color: #000;
    text-decoration: none;
    font-size: 11px;
    text-transform: uppercase;
}

.a-tabs-style a.active {
    color: #10A0E3;
    border-bottom: solid 3px #10A0E3;
}

.a-tabs-style a svg {
    font-size: 18px;
}

.copy-icon {
    color: #10A0E3;
    margin: 0 15px;
    font-size: 20px;
    float: right;
    cursor: pointer;
}

.select-buttons div.item {
    border: solid 1px #cccccc;
    border-radius: 8px;
    padding: 24px 15px;
    text-align: center;
    cursor: pointer;
}

.select-buttons div.item.active {
    border: solid 2px #10A0E3;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.select-buttons div.item:hover {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.select-buttons div.item svg {
    text-align: center;
    display: inline-block;
    margin-bottom: 2px;
    font-size: 25px;
}

.datatable-actions .dropdown label input[type="checkbox"], 
.menu-btn-action-show .dropdown label input[type='checkbox'] {
    vertical-align: bottom;
    position: relative;
    top: -2.5px;
}

.zone-refresh {
    cursor: pointer;
    display: flex;
    flex-direction: row;

}



.zone-refresh:hover .span-refresh {
    display: contents;
}

.span-refresh {
    display: none;
}

.main-bar-right-side {
    display: flex;
    align-items: center;
    justify-content: end;
}

.button-add-a {
    font-size: 12px !important;
    cursor: pointer;
    font-weight: normal;
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
}

.blue-text {
    color: #10A0E3;
}

.body-header {
    /* Select the new status */

    height: 23px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.0015em;
    color: #03A9F4;


}

.check {
    align-items: center;
    background-color: white !important;
    border: 0.5px solid;
    border-color: rgba(186, 186, 186, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    height: 74px;
    justify-content: center;
    padding: 8px;
    width: 100%;
    cursor: pointer;
    transition: 0.4s;
}

.check:hover {
    background-color: rgba(3, 169, 244, 0.1) !important;
}

.check-active {
    align-items: center;
    background-color: white;
    border: 0.5px solid;
    border-color: rgb(3, 169, 244);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    height: 74px;
    justify-content: center;
    padding: 8px;
    width: 100%;
    cursor: pointer;
    box-shadow: 0 2px 11px #2c2b2a33, 1px 1px 1px #00000026;
}

.solicitada {
    color: #000a12;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.07px;
    line-height: normal;
    text-align: center;
    width: fit-content;
}

.current-status-main {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    width: 426px;
}

.current-status-main-inner {
    align-items: center;
    display: flex;
    gap: 10px;
    width: fit-content;
}

.setup {
    align-items: center;
    border: 0px none;
    display: flex;
    gap: 8px;
    width: fit-content;
}

.body-header-right {
    /* Customer: Maria Fernandez Riduaur */

    width: 216px;
    height: 18px;

    /* Caption */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    text-align: right;
    letter-spacing: 0.004em;

    /* Dark Grey */
    color: #606060;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.sortby-parent .sort-icon {
    visibility: hidden;
    font-size: 16px;
    margin-left: 3px;
}

.sortby-parent:hover .sort-icon {
    visibility: visible;
    font-size: 16px;
    margin-left: 3px;
}

.data-customer-status {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.subheader-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customer-status {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.customer-current-status {
    display: flex;
}

.poppins-12 {
    font-size: 12px !important;
    font-family: 'Poppins' !important;
}

.poppins-10 {
    font-size: 10px !important;
    font-family: 'Poppins' !important;
}

.btns-footer {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1.75rem 0 1.75rem 0;
    gap: 1rem;
}

.btn-width-2 {
    width: 10rem !important;
}

.close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
}

.close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75;
}

.close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    opacity: 1;
}

.close:disabled,
.close.disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    opacity: 0.25;
}

.close-white {
    filter: invert(1) grayscale(100%) brightness(200%);
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.file-upload-r input.upload {
    position: absolute;
    display: none;
    filter: alpha(opacity=0);
    width: 0;
    margin-top: -25px;
    pointer-events: none;
}

.file-upload-r {
    padding: 7px 0 6px 20px;
    border-radius: 0px 5px 5px 0px;
    width: 110px;
    float: right;
    cursor: pointer;
    font-size: 12px;
    background-color: #10A0E3 !important;
    border: solid 1px #10A0E3;
    color: #ffffff !important;
    margin-bottom: -35px;
    font-weight: 600
}

.input-icon-append {
    border-radius: 5px !important;
}

.icon-show-append {
    font-size: 22px;
    margin: 5px 10px 0px 0px;
    cursor: pointer;
}

.btn-input-append {
    text-decoration: none;
    color: rgb(16, 160, 227);
    text-transform: capitalize;
}

.m-modal-cal{    
    display: inline-flex;    
    justify-content: center;    
    align-items: center;    
    padding: 8px;    
    width: 32px;    
    height: 32px;    
    background: #FFFFFF;    
    border: 0.5px solid rgba(33, 33, 33, 0.2);    
    border-radius: 4px;    
    margin: 0 3px 3px 0;    
}

span.m-modal-cal.actived{
    border: 1px solid #03A9F4;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0px 2px 11px rgba(44, 43, 42, 0.2);
}

.m-modal-cal:hover{
    cursor: pointer;
    border: 1px solid #03A9F4;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    background-image: none;
}

/* Risk Control */
.nav.tab-risk-control a.nav-link{
    color: #bababa;
}
.nav.tab-risk-control a.nav-link.active{
    color: #03A9F4;
    font-weight: 700;
}
div.accordion p.card-text{
    font-weight: 600;
    cursor: pointer;
}
div.accordion p.card-text span svg{
    float: right;
    font-size: 20px;
}
div.alert span{
    float: right;
    margin-top: -30px;
    font-size: 20px;
    color: #03A9F4;
    cursor: pointer;
}
div.form-inline.alert-status{
    width: max-content;
}
div.form-inline.alert-status img{
    margin-right: 10px;
}
span.span-detail-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.015em;
    text-transform: uppercase;
    color: #606060;
}
span.span-detail-name.color {
    border-color: #FF867C;
}
span.span-detail-date {
        font-size: 8px;
        letter-spacing: 0.015em;
        text-transform: uppercase;
        color: #606060;
        border: 1px solid #60606060;
        border-radius: 5px;
        width: 90%;
        display: inline-block;
}
span.span-detail-date.color {
    border-color: #FFA726;
}
div.alert pre{
    font-size: 10px!important;
    white-space: pre-wrap;
    overflow-x: hidden;
    overflow-y: auto;
}
button.btn-modal-risk {
    width: 45%;
    margin: 5px;
}
.rc-tooltip-placement-top .rc-tooltip-inner {
    background-color: #03A9F4!important;
    white-space: nowrap;
    padding: 2px 4px!important;
    border-radius: 8px!important;
}
.rc-tooltip-placement-top .rc-tooltip-arrow, .rc-tooltip-placement-topLeft .rc-tooltip-arrow, .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: #03A9F4!important;
}

.title-header-ctable{
    display: flex;
    width: max-content;
    align-items: center;
    gap: 8px;
}

.title-header-ctable p{
    font-size: 10px;
    margin-bottom: -2px;
}

iframe.creatorContainer{
    width: 100%;
    position: absolute;
    overflow: hidden;
    height: 100%;
    bottom: 0;
    padding-top: 48px;
    padding-left: 100px;
}

.info-icon.in-input{
    position: absolute;
    z-index: 200;
    top: 13px;
    right: 12px;
    font-size: 20px;
}

/* Remove Arrows/Spinners Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove Arrows/Spinners Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.DropdownButtonV2 a{
    padding: .25rem 1rem!important;
    margin: 0!important;
}

.DropdownButtonV2 a:hover{
    color: #212529!important;
}

.slide-view-header-right button.bordered {
    font-size: 10px;
    margin-left: 20px;
    font-weight: 400;
}

.slide-view-header-right button svg {
    font-size: 14px;
    margin-right: 2px;
}

.slide-view-header-right .element-refresh-data {
    display: inline-flex;
    vertical-align: text-top;
    cursor: pointer;
}

.element-refresh-data span {
  display: none;
}

.slide-view-header-left{
    padding: 0 5px;
    align-items: center;
    gap: 56px;
    border-radius: 8px;
    border: 1px solid var(--color-light-light);
}

.slide-view-header-left span.header-vertical-line {
    height: 47px;
    border-left: 1px solid #e5e5e5;
}

.slide-view-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    font-size: 10px;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    width: 28px;
    height: 28px;
}

.circle svg {
    font-size: 18px;
}

.text-lines {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    font-size: 10px;
    margin-left: 5px;
    white-space: nowrap;
}

.bg-qfilter{
    min-width: 150px;
    height: 58px;
    padding-left: 6px;
    padding-right: 3px;
    gap: 8px;
    flex: 1 0;
    border-radius: 8px;
    border: 1px solid var(--color-light-light);
    margin-right: 4px;
    cursor: pointer;
}

.bg-qfilter.active {
    box-shadow: 0px 2px 2px 0px rgba(33, 37, 41, 0.10);
    border: 1px solid var(--color-primary);
}

.bg-qfilter.title {
    min-width: max-content;
    height: auto;
    padding: 2px 4px;
}

.bg-qfilter:not(.bg-qfilter-wo-hover, .bg-qfilter-disabled):hover {
    border: 1px solid var(--color-primary);
}

.bg-qfilter.bg-qfilter-wo-hover {
    border: 1px solid var(--color-dark-dark);
    cursor: default!important;
}

.bg-qfilter.bg-qfilter-disabled {
    cursor: not-allowed;
    opacity: 0.5;
    filter: grayscale(1);
}

.slide-view-quick-filter{
    display: flex;
    padding-top: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    font-size: 10px;
}

.scroll-buttons span {
    font-size: 35px;
    color: var(--color-dark-light);
    cursor: pointer;
}

.scroll-buttons span.btn-scroll-right {
    margin-left: -10px;
    margin-right: -25px;
}

.scroll-buttons span.btn-scroll-left {
    margin-left: -25px;
    margin-right: -10px;
}

.scroll-buttons span:hover {
    color: var(--color-primary);
}

#scrollQuickFilter{
    width: 100%;
    height: 75px!important;
}

#scrollQuickFilter div.ScrollbarsCustom-Track {
    height: 0!important;
    width: 0!important;
}

/* beginning --- custom table css */
div.slide-view-container div.ScrollbarsCustom.table-responsive {
    height: calc(100vh - 315px)!important;
    overflow: auto;
    overscroll-behavior: contain!important;
}

div.slide-view-container div.ScrollbarsCustom.table-responsive .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
    width: calc(100% - 20px)!important;
    height: 4px!important;
}

.slide-view-container .table-responsive thead tr {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); 
  z-index: 2;
}
/* end --- custom table css */

.icon-info-text{
    margin-top: -2px;
    margin-left: 2px;
    font-size: 10px;
}

.menu-btn-action-hide .menu-btn-dropdown button.dropdown-toggle::after {
    display:none;
}

.menu-btn-action-hide .menu-btn-dropdown button svg {
    font-size: 25px;
    color: var(--color-dark-dark);
}

.menu-btn-action-hide .menu-btn-dropdown .dropdown-menu {
    font-size: 12px;
}



.label-item-menu {
    color: #6C757D;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    padding: 0 1rem;
}

.format-date-time{
    color: #868E96;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.015px;
}

.content-summary-header{
    display: flex;
}

.sticky-row {
	position: sticky;
	right: 0;
	z-index: 1;
	background: white !important;
}

.sticky-row:is(th, td):has(.dropdown.show) {
	z-index: 2;
}

.divider .show-more-button {
	background-color: #ffffff;
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	border-radius: 15px;
	padding: 5px 5px 5px 10px;
	border: 1px solid #21212133;
	border-color: #21212133;
	color:#21212180;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.0025em;
	text-transform: uppercase;
}
.divider .show-more-button.expand {
	border-color: #03A9F4;
	color:#03A9F4;
}

.divider .show-more-button:hover{
	background-color: rgba(211, 212, 213, 0.25);
}

.divider .show-more-button:focus {
	box-shadow: none
}

.loading-bar {
	opacity: .5;
	line-height: 1.5;
	border-radius: 4px;
	margin-bottom: 0;
	&.skeleton {
		min-height: 14px;
	}
}

.skeleton {
	background: rgba(255,255,255);
	background: -moz-linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
	background: -webkit-linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
	background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
	background-color: #dddddd;
	background-size: 100px 100%;
	background-repeat: no-repeat; 
	background-position: left -40px top 0; 
	animation: shine 1s ease infinite; 
}

#statisticsBalance{
    font-weight: normal;
}

.notificationActionPlus, .notificationActionRemove{
	background: transparent;
	height: 34px;
	border: none;
	font-size: 14px;
}

#vcard-payment{
    background: url("/src/assets/images/payment-method-background.svg");
    background-size: cover;
    height: 290px;
    font-size: 12px;
}

@keyframes shine {
	to {
		background-position: right -40px top 0;
	}
}

/**** MEDIA ****/

@media (max-width:1747px) {
    .span-refresh {
        display: none;
    }

    .zone-refresh:hover .span-refresh {
        display: none;
    }
}

@media (max-width:1568px) {
    .main-span-button {
        display: none;
    }
    
    .modal-dialog .modal-body .main-span-button {
        display: inline-block;
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(-360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(-360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@media (max-width: 1403px) {
    .btc-actions-header {
        display: none;
    }
}

@media (min-width: 1600px) {
    .element-refresh-data:hover span {
        display: inline-block;
    }
}

@media (min-width:960px) {
    .main-left-bar.open {
        left: -170px;
    }

    .main-body.open-menu {
        padding: 0 44px 0 110px;
    }

    .main-left-bar.open .main-menu ul li a,
    .main-left-bar.open .main-menu-bottom ul li a {
        padding-right: 0;
        color: #ffffff !important;

    }

    .main-left-bar.open .main-menu ul li a .icon,
    .main-left-bar.open .main-menu-bottom ul li a .icon {
        color: #5a6a80 !important;
        left: 136px;

    }

    .main-left-bar.open #main-branding-icon img {
        opacity: 1;
        right: 20px;
    }

    .main-left-bar.open #main-branding img {
        opacity: 0;
    }

    .main-body.open-menu .main-top-bar {
        padding: 0 0 0 65px;
    }
}

@media (max-width:378px) {

    .card .col-2,
    .card .col-10 {
        width: 100%;
        text-align: center;
        margin: 5px 0;
    }
}

@media (max-width:480px) {
    .card-login {
        width: 395px !important;
        padding: 40px 50px;
    }

    #vterminal-container {
        width: 23em;
    }

    .btn-filter-reset {
        width: 40%;
    }

    .btn-filter-see {
        width: 57%;
    }

    .hide-sm-sm {
        display: none !important;
    }
}

@media (max-width:534px) {

    #right-panel,
    #filter-right-panel,
    #vterminal-rigth-panel,
    #paybill-rigth-panel {
        width: 100%;
    }

    #filter-right-panel.open {
        margin-right: 0;
    }

    #filter-right-panel.open div.btnFilter {
        width: 96%;
        -webkit-transition: right 0.3s ease, -webkit-box-shadow 0.3s ease;
        -moz-transition: right 0.3s ease, -moz-box-shadow 0.3s ease;
        transition: right 0.3s ease, box-shadow 0.3s ease;
    }

    .popover-body {
        min-width: auto;
    }

    .addInvoice {
        left: 10%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    span.span-detail-name {
        font-size: 6px;
    }
    span.span-detail-date {
        font-size: 6px;
        padding: 0 20%;
    }
}

@media (max-width:576px) {
    .main-top-bar .profile-name {
        display: none;
    }

    #vterminal-container {
        width: 28em;
    }

    #vterminal-container .main-card-body {
        padding: 2rem;
    }

    .vterminal-tabs {
        margin: -2rem -2rem 0rem -2rem !important
    }

    .card-brands.accordion-right-corner {
        position: revert;
    }

    .cc-form .cc-field:first-child {
        width: 100%;
        border-bottom: solid 1px #ced4da;
        border-right: none
    }

    .cc-form .cc-field {
        width: 33.333%;
        border-right: solid 1px #ced4da;
    }

    .cc-form .cc-field:last-child {
        border-right: none
    }

    .cc-form .cc-field input {
        padding: 0 10px;
    }

    .cc-form .cc-field label {
        padding-left: 10px;
    }

    /* .card-brands {
        margin-top: 10px !important;
    } */

    .main-cont {
        padding: 30px !important;
    }

    #filter-right-panel div.section-payment-information div {
        width: auto;
    }

    .main-top-bar .top-bar-sub .row .col-5 {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .main-top-bar .org-name {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        width: 30%;
        margin: 0 0 0 20px;
    }
}

@media (max-width:800px) {
    .main-left-bar:not(.open) {
        -webkit-box-shadow: 0px 0px 0px 850px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 0px 850px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 0px 850px rgba(0, 0, 0, 0.2);
    }
    .content-summary-header{
		display: none!important;
	}
    .slide-view-header-left{
        padding: 5px 5px!important;
    }
}

@media (max-width:960px) {
    .show-sm {
        display: block !important;
    }

    .hide-sm {
        display: none !important;
    }

    #vterminal-container {
        width: 40em;
    }

    #vterminal-container .main-card-body {
        padding: 3.4rem 4rem;
    }

    .vterminal-tabs {
        margin: -3.4rem -4rem 0rem -4rem !important
    }

    .main-top-bar {
        padding: 0;
    }

    .coming-soon {
        width: 100%;
    }

    #parentViewSlide {
        width: 100%;
        right: -100%;
    }
}

@media (max-width:1050px) {
    .hide-smd {
        display: none;
    }

    .show-smd {
        display: block !important;
    }
}

@media (max-width:1300px) {
    .full-1260 {
        width: 100% !important;
    }

    .order-1260-1 {
        order: 1 !important;
    }

    .order-1260-2 {
        order: 2 !important;
    }

    .offset-1260-0 {
        margin-left: 0 !important;
    }

    .height-1260-auto {
        min-height: auto !important;
    }

    .pagination-section-1,
    .pagination-section-3 {
        display: none;
    }

    .report-paginator {
        width: -moz-available !important;
        width: -webkit-fill-available !important;
        width: fill-available !important;
        margin-bottom: 5px;
    }
}

@media (max-width: 1260px) {
    .menu-btn-action-show {
        display: none!important;
    }

    .menu-btn-action-hide {
        display: block;
    }
}
   
@media (min-width: 1300px) {
    .menu-btn-action-show {
        display: flex;
    }

    .menu-btn-action-hide {
        display: none;
    }
}

@media (max-width:1330px) {
    .hide-md {
        display: none;
    }

    .show-md {
        display: block !important;
    }

    .coming-soon {
        width: 50em;
    }
}

@media (max-width:720px) {
    .bottom-btn{
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 20px 44px;
        background-color: #ffffff;
        width: 100%;
        display: flex;
        border-top: solid 1px #e7e6e6;
    }

    .bottom-btn .btn{
        margin: 0 10px 0 0!important;
        width: 50%;
    }

    .bottom-btn .btn:last-child{
        margin: 0 0 0 10px!important;
    }

    .bottom-btn .btn.singleButton{
        width: 100%;
        margin: 0!important;
    }

	#invoicesBuilder .body-builder {
		margin-bottom: 50px;
	}
}